var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"demo-spin-article"},[_c('div',[_c('div',{staticClass:"col-md-12 mb-3"},[_c('label',{staticClass:"form-label col-label required tw-text-xs"},[_vm._v(_vm._s(_vm.$t('workingHours.employeeName')))]),_c('Select',{staticClass:"from-label",class:{
                        'is-invalid': _vm.errors.has('employee_id')
                    },attrs:{"filterable":"","remote":"","remote-method":_vm.searchEmployee,"loading":_vm.loading},model:{value:(_vm.model.employee_id),callback:function ($$v) {_vm.$set(_vm.model, "employee_id", $$v)},expression:"model.employee_id"}},_vm._l((_vm.employeeProfile),function(emp,index){return _c('Option',{key:index,attrs:{"value":emp.employee_id}},[_vm._v(" "+_vm._s(emp.card_id + '-' + emp.employee_name_kh + '-' + emp.employee_name_en)+" ")])}),1),(_vm.errors.has('employee_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('employee_id'))+" ")]):_vm._e()],1),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"form-label col-label required"},[_vm._v(_vm._s(_vm.$t('workingHours.fromTime')))]),_c('time-picker',{class:{
                            'is-invalid': _vm.errors.has('time_from')
                        },staticStyle:{"width":"270px"},attrs:{"type":"time","placeholder":"HH:mm","format":"HH:mm","confirm":""},model:{value:(_vm.model.from_time),callback:function ($$v) {_vm.$set(_vm.model, "from_time", $$v)},expression:"model.from_time"}}),(_vm.errors.has('time_from'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('time_from'))+" ")]):_vm._e()],1),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"form-label col-label required"},[_vm._v(_vm._s(_vm.$t('workingHours.toTime')))]),_c('time-picker',{class:{
                            'is-invalid': _vm.errors.has('time_to')
                        },staticStyle:{"width":"280px"},attrs:{"type":"time","placeholder":"HH:mm","format":"HH:mm","confirm":""},model:{value:(_vm.model.to_time),callback:function ($$v) {_vm.$set(_vm.model, "to_time", $$v)},expression:"model.to_time"}}),(_vm.errors.has('time_to'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('time_to'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"form-label col-label required"},[_vm._v(_vm._s(_vm.$t('workingHours.totalHour')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.total_hour),expression:"model.total_hour"}],staticClass:"form-control",staticStyle:{"width":"270px"},attrs:{"type":"text","placeholder":"Input Working Hours"},domProps:{"value":(_vm.model.total_hour)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "total_hour", $event.target.value)}}}),(_vm.errors.has('total_hour'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('total_hour'))+" ")]):_vm._e()]),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"form-label col-label required"},[_vm._v(_vm._s(_vm.$t('workingHours.workingDate')))]),_c('DatePicker',{staticStyle:{"width":"100%"},attrs:{"type":"date","placeholder":"DD-MM-YYYY","format":"dd-MM-yyyy"},on:{"on-change":_vm.workingDate},model:{value:(_vm.model.working_date),callback:function ($$v) {_vm.$set(_vm.model, "working_date", $$v)},expression:"model.working_date"}}),(_vm.errors.has('working_date'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('working_date'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"form-label col-label required"},[_vm._v(_vm._s(_vm.$t('workingHours.cycleYear')))]),_c('date-Picker',{class:{
                            'is-invalid': _vm.errors.has('cycle_year')
                        },staticStyle:{"width":"270px"},attrs:{"value":_vm.model.cycle_year,"type":"year","format":"yyyy","placeholder":"Select Year"},on:{"on-change":_vm.cycleYearChange}}),(_vm.errors.has('cycle_year'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('cycle_year'))+" ")]):_vm._e()],1),_c('div',{staticClass:"col-md-6"},[_c('label',{staticClass:"form-label col-label required"},[_vm._v(_vm._s(_vm.$t('workingHours.cycleMonth')))]),_c('date-Picker',{class:{
                            'is-invalid': _vm.errors.has('cycle_month')
                        },staticStyle:{"width":"280px"},attrs:{"value":_vm.model.cycle_month,"type":"month","placeholder":"Select Month","format":"MM"},on:{"on-change":_vm.cycleMonthChange}}),(_vm.errors.has('cycle_month'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('cycle_month'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-label form-label tw-text-xs"},[_vm._v(" "+_vm._s(_vm.$t('itemOvertime.description'))+" ")]),_c('div',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.remark),expression:"model.remark"}],staticClass:"form-control",attrs:{"rows":"4"},domProps:{"value":(_vm.model.remark)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "remark", $event.target.value)}}})])]),_c('div',[_c('div',{staticClass:"tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3"},[_c('ts-button',{staticClass:"btn-gray",on:{"click":function($event){$event.preventDefault();return (function () { return _vm.$emit('cancel'); }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),(!_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","outline":"","waiting":_vm.waiting_new,"disabled":_vm.waiting_new || _vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onSaveAddNew.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('saveAddNew'))+" ")]):_vm._e(),(!_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting,"disabled":_vm.waiting_new || _vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]):_vm._e(),(_vm.isUpdate)?_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting,"disabled":_vm.waiting_new || _vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onUpdate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('update'))+" ")]):_vm._e()],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }