<template>
    <div>
        <ts-page-title
            :title="$t('workingHours.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('workingHours.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <div class="tw-space-x-2">
                    <Button type="info" ghost @click="addNew">
                        {{ $t('addNew') }}
                    </Button>
                    <Button
                        type="success"
                        @click.prevent="onShowExport"
                        :loading="exporting"
                    >
                        <i class="fas fa-file-excel"></i> {{ $t('export') }}
                    </Button>
                    <Button
                        type="primary"
                        ghost
                        icon="ios-cloud-upload"
                        @click="importIncentive"
                    >
                        {{ $t('import') }}
                    </Button>
                </div>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        v-model="search"
                        search
                        :placeholder="$t('workingHours.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 400px')"
                        @on-blur="() => (width = 'width: 400px')"
                    />
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                max-height="700"
                :loading="loading"
                size="small"
            >
                <template slot-scope="{ row }" slot="employeeName">
                    {{ row.employee_name_kh + ' | ' + row.employee_name_en }}
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="onDelete(row)"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                        placement="left"
                        :transfer="true"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
        </ts-panel>
        <Modal
            v-model="showForm"
            draggable
            sticky
            scrollable
            :mask="false"
            :footer-hide="true"
            :z-index="1022"
            width="620px"
            :title="$t('workingHours.workingHours')"
        >
            <FormComponent
                ref="form_action"
                @cancel="clearEdit"
                @fetchData="fetchData"
            />
        </Modal>
        <Modal
            v-model="show_export_form"
            :centered="true"
            :closable="false"
            :zIndex="1020"
            :title="$t('export')"
            :okText="$t('yes')"
            :cancelText="$t('no')"
            @on-ok="onExport"
            @on-cancel="clearEdit"
            :confirmLoading="exporting"
            width="400px"
        >
            <ts-loading-banner
                :loading="export_resource_loading"
                class="ts-space-y-2"
            >
                <div class="mb-3">
                    <label class="form-labe col-label">{{
                        $t('branchName')
                    }}</label>
                    <Select
                        v-model="model.branch_id"
                        multiple
                        :max-tag-count="1"
                        :filterable="true"
                        :filter-by-label="true"
                    >
                        <Option
                            v-for="item in branches"
                            :value="item.branch_id"
                            :key="item.branch_id"
                            :label="item.branch_name_en"
                        >
                            {{ item.branch_name_en }}
                        </Option>
                    </Select>
                </div>
                <div class="mb-3">
                    <label class="col-label form-label">{{
                        $t('workingHours.employeeName')
                    }}</label>
                    <Select
                        v-model="model.employee_id"
                        multiple
                        filterable
                        remote
                        :loading="loading"
                    >
                        <Option
                            v-for="(emp, index) in employeeProfile"
                            :value="emp.employee_id"
                            :key="index"
                        >
                            {{
                                emp.card_id +
                                '-' +
                                emp.employee_name_kh +
                                '-' +
                                emp.employee_name_en
                            }}
                        </Option>
                    </Select>
                </div>
            </ts-loading-banner>
        </Modal>
        <Modal
            v-model="showFormImport"
            draggable
            sticky
            scrollable
            :mask="false"
            :footer-hide="true"
            :z-index="1022"
            :title="$t('workingHours.importWorkingHours')"
        >
            <ImportIncentive
                ref="import_working_hours"
                @cancel="() => (showFormImport = false)"
                @fetchData="fetchData"
            />
        </Modal>
    </div>
</template>
<script>
import FormComponent from './form'
import { mapState, mapActions } from 'vuex'
import { trim } from 'lodash'
import { Errors } from 'form-backend-validation'
import * as FileDownload from 'downloadjs'
import ImportIncentive from './import-working-hours'
import { debounce } from 'lodash'

export default {
    name: 'working-hours',
    components: {
        FormComponent,
        ImportIncentive
    },
    data () {
        return {
            exporting: false,
            show_export_form: false,
            export_resource_loading: false,
            showFormImport: false,
            loading: false,
            export: false,
            width: 'width: 300px',
            showForm: false,
            branches: [],
            employeeProfile: [],
            model: {
                branch_id: [],
                employee_id: null
            }
        }
    },
    computed: {
        ...mapState('payroll/workingHours', ['resources', 'pagination']),
        search: {
            get () {
                return this.$store.state.payroll.workingHours.search
            },
            set (newValue) {
                this.$store.commit('payroll/workingHours/SET_SEARCH', newValue)
                this.$store.commit('payroll/workingHours/RESET_CURRENT_PAGE')
            }
        },
        columns () {
            return [
                {
                    title: this.$t('workingHours.employeeName'),
                    slot: 'employeeName',
                    width: 240,
                    sortable: true
                },
                {
                    title: this.$t('workingHours.fromTime'),
                    key: 'from_time',
                    width: 230,
                    sortable: true
                },
                {
                    title: this.$t('workingHours.toTime'),
                    key: 'to_time',
                    width: 230,
                    sortable: true
                },
                {
                    title: this.$t('workingHours.totalHour'),
                    key: 'total_hour',
                    width: 230,
                    sortable: true
                },
                {
                    title: this.$t('workingHours.workingDate'),
                    key: 'working_date',
                    width: 230,
                    sortable: true
                },
                {
                    title: this.$t('workingHours.cycleYear'),
                    key: 'cycle_year',
                    width: 230,
                    sortable: true
                },
                {
                    title: this.$t('workingHours.cycleMonth'),
                    key: 'cycle_month',
                    width: 230,
                    sortable: true
                },
                {
                    title: this.$t('workingHours.description'),
                    key: 'remark',
                    width: 230,
                    sortable: true
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    className: 'tw-space-x-2',
                    width: 90
                }
            ]
        }
    },
    methods: {
        ...mapActions('payroll/workingHours', ['formViewModel']),

        async fetchResource (attributes) {
            this.loading = true
            let response = await this.formViewModel({
                params: attributes
            })
            if (attributes.fnName) {
                attributes.fnName.split(',').forEach(fn => {
                    this[fn] = response[fn]
                })
            }
            this.loading = false
        },
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('payroll/workingHours/fetch', attributes)
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        fetchBranch () {
            this.loading = true
            this.$store
                .dispatch('auth/user/getBranchAssigned')
                .then(response => {
                    this.branches = response.data
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        addNew () {
            this.showForm = true
            this.$refs.form_action.clearInput()
        },
        onEdit (record) {
            this.showForm = true
            this.$store.commit('payroll/workingHours/SET_EDIT_DATA', record)
            this.$refs.form_action.fetchResource({
                fnName: 'workingHours,employeeProfile'
            })
            this.$refs.form_action.setEditData()
        },
        async onDelete (row) {
            row._deleting = true
            this.$store
                .dispatch('payroll/workingHours/destroy', row.record_id)
                .then(response => {
                    this.fetchData()
                    row._deleting = false
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                    row._deleting = false
                })
        },
        onShowExport () {
            this.show_export_form = true
            this.fetchBranch()
            this.fetchResource({
                fnName: 'employeeProfile'
            })
            this.clearInput()
        },
        importIncentive () {
            this.showFormImport = true
        },
        onExport () {
            this.exporting = true
            this.errors = new Errors()
            this.$store
                .dispatch('payroll/workingHours/exportExcel', this.model)
                .then(response => {
                    let filename = trim(
                        response.headers['content-disposition']
                            .substring(
                                response.headers['content-disposition'].indexOf(
                                    'filename'
                                )
                            )
                            .replace('filename=', ''),
                        '"'
                    )
                    FileDownload(response.data, filename)
                    this.show_export_form = false
                    this.$refs.form_action.clearInput()
                })
                .catch(err => {
                    let error = JSON.parse(
                        String.fromCharCode.apply(null, new Uint8Array(err))
                    )
                    this.$toasted.error(error.message)
                    this.errors = new Errors(error.errors)
                })
                .finally(() => {
                    this.exporting = false
                })
        },
        clearEdit () {
            this.showForm = false
            this.$store.commit('payroll/workingHours/SET_EDIT_DATA', {})
        },
        clearInput () {
            this.errors = new Errors()
            this.model.branch_id = null
            this.model.employee_id = null
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'MONTHLY INCENTIVE BONUS',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search })
        }, 500)
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    }
}
</script>
